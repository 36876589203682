"use client";

import { isUndefined } from "lodash";
import { useMemo } from "react";

import CaretIcon from "@/images/icons/caret-down.svg";
import { EnergyType, PlanDetails, Tariffs } from "@/types/data";
import { AccordionGeneric } from "../../../../AccordionGeneric/AccordionGeneric";
import styles from "./ProductCardFooter.module.scss";
import LinkSolstice from "../../../../LinkSolstice/LinkSolstice";

type Rate = {
	tariff: string;
	charge: number;
};

type RateProps = {
	gas?: boolean;
	step1: Rate[];
	step2: Rate[];
	peak: Rate[];
	usage: Rate[];
	usage_gj: Rate[];
	offPeak: Rate[];
	daily: Rate[];
	solar: Rate[];
};

interface ProductCardFooterProps {
	children?: React.ReactNode;
	plan: Partial<PlanDetails> & Tariffs;
	energy: EnergyType;
	bpid?: string;
}

export const ProductCardFooter = ({ children, plan, energy, bpid }: ProductCardFooterProps) => {
	const isGas = energy === "gas";

	const rates: RateProps = useMemo(() => {
		const tariffs: string[] = [plan?.TARIFF1, plan?.TARIFF2, plan?.TARIFF3, plan?.TARIFF4, "GAS"]
			.filter(val => !isUndefined(val))
			.filter(val => val !== "N")
			.map(val => val as string);

		return {
			step1: tariffs.map(tariff => ({
				tariff,
				charge: parseFloat(plan[`${tariff}_STEP1`] ?? "0") * 100,
			})),
			step2: tariffs.map(tariff => ({
				tariff,
				charge: parseFloat(plan[`${tariff}_STEP2`] ?? "0") * 100,
			})),
			offPeak: tariffs.map(tariff => ({
				tariff,
				charge: parseFloat(plan[`${tariff}_OFFPEAK`] ?? "0") * 100,
			})),
			usage_gj: tariffs.map(tariff => ({
				tariff,
				charge: parseFloat(plan[`${tariff}_USAGE_GJ`] ?? "0"),
			})),
			usage: tariffs.map(tariff => ({
				tariff,
				charge: parseFloat(plan[`${tariff}_USAGE`] ?? "0") * 100,
			})),
			peak: tariffs.map(tariff => ({
				tariff,
				charge: parseFloat(plan[`${tariff}_PEAK`] ?? "0") * 100,
			})),
			solar: tariffs.map(tariff => ({
				tariff,
				charge: parseFloat(plan[`${tariff}_FIT`] ?? "0") * 100,
			})),
			daily: tariffs.map(tariff => ({
				tariff,
				charge: parseFloat(plan[`${tariff}_DAILY`] ?? "0"),
			})),
		};
	}, [plan]);
	return (
		<div
			className={styles["product-footer"]}
			data-rates-button="Rates"
		>
			<AccordionGeneric
				className={styles["product-footer__rates"]}
				expandedClassName={styles["product-footer__rates--expanded"]}
				name="Rates"
				header={
					<>
						<span>Rates</span> <CaretIcon />
					</>
				}
				headerClassName={styles["product-footer__rates-header"]}
				body={<Rates {...rates} />}
			/>
			{!isGas && bpid && (
				<LinkSolstice
					href={bpid}
					className={styles["product-footer__info"]}
				>
					Basic plan information
				</LinkSolstice>
			)}

			<div className={styles["product-footer__cta"]}>{children}</div>
			<div className={styles["product-footer__disclaimer"]}>{!isGas && <Terms />}</div>
		</div>
	);
};

const Terms = () => (
	<p>
		*This estimate is based on the tariffs associated with the meter configured at your property and
		an estimated level of usage. Your household’s actual usage may vary from the estimated usage.
		This estimate excludes solar payments, concessions and benefits, which will be applied later if
		you’re eligible and appear on your bill.
	</p>
);

const Rates = (rates: RateProps) => {
	const hasSolar = rates.solar.some(rate => rate.charge);

	return (
		<dl className={styles["product-rates"]}>
			<div>
				<dt>Supply charge</dt>
				<dd>
					{rates.daily
						?.filter(rate => rate.charge > 0)
						.map(rate =>
							rate.charge && rate.tariff?.indexOf("GAS") > -1 ? (
								<p key={rate.charge}>Anytime ${rate.charge}/day</p>
							) : (
								!isNaN(rate.charge) && (
									<p key={rate.tariff}>
										Anytime ({rate.tariff}) ${rate.charge.toFixed(2)}/day
									</p>
								)
							),
						)}
				</dd>
			</div>
			<div>
				<dt>Usage charge</dt>
				<dd>
					{rates.usage_gj
						.filter(rate => rate.charge > 0)
						.map(
							rate =>
								rate.charge && (
									<p key={rate.tariff}>
										Anytime ({rate.tariff}) ${rate.charge}/GJ
									</p>
								),
						)}
					{rates.usage
						.filter(rate => rate.charge > 0)
						.map(
							rate =>
								rate.charge && (
									<p key={rate.tariff}>
										Anytime ({rate.tariff}) {rate.charge}c/MJ
									</p>
								),
						)}
					{rates.step1
						.filter(rate => rate.charge > 0)
						.map(
							rate =>
								!isNaN(rate.charge) && (
									<p key={rate.tariff}>
										({rate.tariff}) {rate.charge.toFixed(2)}c/kWh
									</p>
								),
						)}
					{rates.step2
						.filter(rate => rate.charge > 0)
						.map(
							rate =>
								!isNaN(rate.charge) && (
									<p key={rate.tariff}>
										Step 2 ({rate.tariff}) {rate.charge.toFixed(2)}c/kWh
									</p>
								),
						)}
					{rates.peak
						.filter(rate => rate.charge > 0)
						.map(
							rate =>
								!isNaN(rate.charge) && (
									<p key={rate.tariff}>
										Peak ({rate.tariff}) {rate.charge.toFixed(2)}c/kWh
									</p>
								),
						)}
					{rates.offPeak
						.filter(rate => rate.charge > 0)
						.map(
							rate =>
								!isNaN(rate.charge) && (
									<p key={rate.tariff}>
										Off Peak ({rate.tariff}) {rate.charge.toFixed(2)}c/kWh
									</p>
								),
						)}
				</dd>
			</div>
			{hasSolar && (
				<div>
					<dt>Solar feed-in</dt>
					<dd>
						{rates.solar
							.filter(rate => rate.charge > 0)
							.map(
								rate =>
									!isNaN(rate.charge) && (
										<p key={rate.tariff}>
											Anytime ({rate.tariff}) +{rate.charge.toFixed(2)}c/kWh
										</p>
									),
							)}
					</dd>
				</div>
			)}
		</dl>
	);
};
